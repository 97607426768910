.App {
    text-align: center;
    margin-top: 150px;
}

.App .Logo {
    position: relative;
    z-index: 10;
}

.App .Logo img {
    max-width: 100%;
    width: 230px;
    border-radius: 230px;
    border: 3px solid #ffffff;
}
