
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li {
    display: inline-block;
}

ul li:not(:first-child) {
    margin-left: 25px;
}

a {
    color: #fff;
}

a:hover {
    color: #00a3e8;
}

a:active {
    color: #046c98;
}
